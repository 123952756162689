<template>
  <div class="bookings-planning-table">
    <!-- NEW BOOKING BUTTON AND SEARCH -->
    <b-row v-if="showSearch" class="mb-2 d-flex flex-wrap justify-content-end">
      <b-col cols="9" md="auto" class="mb-1 mb-md-0 justify-content-end">
        <b-form-input
          id="filterInput"
          ref="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="isBusy"
          debounce="400"
          autofocus
        />
      </b-col>
    </b-row>

    <!-- BOOKINGS TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="bookings"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: inorout -->
          <template #cell(inOrOut)="data">
            <b-badge v-if="data.value === 'CHECKIN'" pill variant="primary">
              Entrada
            </b-badge>
            <b-badge v-if="data.value === 'CHECKOUT'" pill variant="warning">
              Sortida
            </b-badge>
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <b-booking-localizator
              :localizator="data.value"
              :source="data.item.source"
              :highlighted="data.item.highlighted"
              :fictitious="data.item.fictitious"
            />
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="data">
            <b-accommodation
              :uuid="data.item.accommodationUuid"
              :name="data.value"
            />
          </template>

          <!-- Column: client -->
          <template #cell(client)="data">
            <b-client :uuid="data.item.clientUuid" :name="data.value" show-saved :saved="data.item.clientContactSaved" />
          </template>

          <!-- Column: requests -->
          <template #cell(requests)="data">
            <div class="d-flex">
              <!-- BABY COTS -->
              <div v-b-tooltip.hover.top="'Cunes'" class="mr-50">
                <b-badge
                  pill
                  :variant="data.item.babyCots > 0 ? 'primary' : 'secondary'"
                >
                  <span>
                    {{ data.item.babyCots }}
                  </span>
                </b-badge>
              </div>

              <!-- BABY CHAIRS -->
              <div v-b-tooltip.hover.top="'Trones'" class="mr-50">
                <b-badge
                  pill
                  :variant="data.item.babyChairs > 0 ? 'primary' : 'secondary'"
                >
                  <span>
                    {{ data.item.babyChairs }}
                  </span>
                </b-badge>
              </div>

              <!-- SPECIAL REQUESTS -->
              <div v-b-tooltip.hover.top="'Peticions especials'">
                <b-badge
                  pill
                  :variant="data.item.specialRequests ? 'primary' : 'secondary'"
                >
                  <feather-icon icon="KeyIcon" />
                </b-badge>
              </div>
            </div>
          </template>

          <!-- Column: services -->
          <template #cell(services)="data">
            <div class="d-flex">
              <!-- BABY COTS -->
              <div
                v-for="(service, index) in data.item.services"
                :key="`service-${data.item.localizator}-${index}`"
                v-b-tooltip.hover.top="service.description"
                class="mr-50"
              >
                <b-badge pill variant="primary">
                  <feather-icon :icon="getServiceIcon(service.code)" />
                </b-badge>
              </div>
            </div>
          </template>

          <!-- Column: checks -->
          <template #cell(checks)="data">
            <div class="d-flex">
              <!-- BOOKING PENDING AMOUNT -->
              <div v-b-tooltip.hover.top="'Pagament reserva'" class="mr-50">
                <b-badge
                  pill
                  :variant="data.item.pending ? 'danger' : 'success'"
                >
                  <feather-icon icon="DollarSignIcon" />
                </b-badge>
              </div>

              <!-- SECURITY DEPOSIT PENDING AMOUNT -->
              <div v-b-tooltip.hover.top="'Pagament fiança'" class="mr-50">
                <b-badge
                  pill
                  :variant="
                    data.item.pendingSecurityDeposit ? 'danger' : 'success'
                  "
                >
                  <feather-icon icon="LockIcon" />
                </b-badge>
              </div>

              <!-- SERVICES PENDING AMOUNT -->
              <div v-b-tooltip.hover.top="'Pagament serveis'" class="mr-50">
                <b-badge
                  pill
                  :variant="data.item.pendingServices ? 'danger' : 'success'"
                >
                  <feather-icon icon="FileTextIcon" />
                </b-badge>
              </div>

              <!-- CLIENT INSTRUCTIONS SENT -->
              <div v-b-tooltip.hover.top="'Instruccions client'" class="mr-50">
                <b-badge
                  v-if="data.item.clientInstructions"
                  pill
                  variant="success"
                >
                  <feather-icon icon="BriefcaseIcon" />
                </b-badge>
                <b-badge v-else pill variant="danger">
                  <feather-icon icon="BriefcaseIcon" />
                </b-badge>
              </div>

              <!-- OWNER INFORMATION SENT -->
              <div v-b-tooltip.hover.top="'Informació propietari'">
                <b-badge
                  v-if="data.item.ownerInstructions"
                  pill
                  variant="success"
                >
                  <feather-icon icon="KeyIcon" />
                </b-badge>
                <b-badge v-else pill variant="danger">
                  <feather-icon icon="KeyIcon" />
                </b-badge>
              </div>
            </div>
          </template>

          <!-- Column: cleaning -->
          <template #cell(cleaning)="data">
            <b-cleaning-status-badge
              :key="`cleaning-badge-${data.item.uuid}`"
              pill
              :booking-stats-uuid="data.item.bookingStatsUuid"
              :cleaning-completed="data.item.cleaningCompleted"
              :cleaning-provided="data.item.cleaningProvided"
            />
          </template>

          <!-- Column: checkinReminded -->
          <template #cell(checkinReminded)="data">
            <div class="d-flex">
              <!-- EMAIL REMINDER -->
              <div
                v-b-tooltip.hover.top="'Recordatori per Email'"
                class="mr-50"
              >
                <b-badge
                  pill
                  :variant="data.item.emailReminder ? 'success' : 'danger'"
                >
                  <feather-icon icon="MailIcon" />
                </b-badge>
              </div>

              <!-- WHATSAPP REMINDER -->
              <div
                v-b-tooltip.hover.top="'Recordatori per WhatsApp'"
                class="mr-50"
              >
                <b-badge
                  pill
                  :variant="data.item.whatsAppReminder ? 'success' : 'danger'"
                >
                  <feather-icon icon="MessageCircleIcon" />
                </b-badge>
              </div>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormInput,
  BSpinner,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import BCleaningStatusBadge from "@foravila-core/components/b-cleaning-status-badge/BCleaningStatusBadge.vue";
import BClient from "@foravila-core/components/b-client/BClient.vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BBadge,
    BBookingLocalizator,
    BAccommodation,
    BCleaningStatusBadge,
    BClient,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    bookings: {
      type: Array,
      default: () => [],
    },
    isSummary: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: true,
      sortBy: "checkInOutTime",
      sortDesc: false,
      filter: null,
      fields: [],
      selectedBooking: null,
    };
  },
  watch: {
    loading(loading) {
      this.isBusy = loading;
    },
  },
  created() {
    this.initFields();
  },
  methods: {
    initFields() {
      this.fields.push({
        key: "inOrOut",
        label: this.$t("Tipus"),
        sortable: true,
      });
      this.fields.push({ key: "localizator", label: this.$t("Reserva") });
      this.fields.push({ key: "accommodation", label: this.$t("Allotjament") });
      this.fields.push({ key: "client", label: this.$t("Client") });
      if (!this.isSummary) {
        this.fields.push({ key: "guests", label: this.$t("Hostes") });
        this.fields.push({
          key: "checkInOutTime",
          label: this.$t("Hora"),
          sortable: true,
        });
        this.fields.push({ key: "requests", label: this.$t("Peticions") });
        this.fields.push({ key: "services", label: this.$t("Serveis") });
        this.fields.push({ key: "checks", label: this.$t("Detalls") });
        this.fields.push({ key: "cleaning", label: this.$t("Neteja") });
        this.fields.push({
          key: "checkinReminded",
          label: this.$t("Recordatori d'arribada"),
        });
      }
    },
    getServiceIcon(code) {
      if (!code) return "BriefcaseIcon";
      switch (code) {
        case "AIRPORT_TRANSFER":
        case "TRANSFER":
          return "TruckIcon";
        case "CLEANING":
        case "FINAL_CLEANING":
          return "FeatherIcon";
        case "CAR_RENTAL":
          return "KeyIcon";
        case "SUPERMARKET":
          return "ShoppingCartIcon";
        case "HEATED_POOL":
          return "DropletIcon";
        default:
          return "BriefcaseIcon";
      }
    },
    getCleaningBadgeVariant(item) {
      if (!item?.cleaningProvided) {
        return "secondary";
      } else {
        return item?.cleaningCompleted ? "success" : "danger";
      }
    },
    getCleaningBadgeText(item) {
      if (!item?.cleaningProvided) {
        return "Propietari";
      } else {
        return item?.cleaningCompleted ? "Completada" : "Pendent";
      }
    },
  },
};
</script>

<style lang="scss">
.bookings-planning-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
